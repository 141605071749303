import React from "react";
import LokalitaTemplate from "../../templates/LokalitaTemplate";
import { StaticQuery, graphql, Link } from "gatsby"
import RegionyTemplate from "../../templates/RegionyTemplate";
import RegionyTemplateV2 from "../../templates/RegionyTemplateV2";



const faqData = [
  {
    otazka: "Co všechno obsahuje cena?",
    odpoved: `Ta obsahuje vyzvednutí zesnulého kdekoliv po Praze a blízkého okolí, převezení do krematoria, samotný žeh a následné uložení do dočasné urny. Doručení urny do vlastních rukou kamkoliv po Praze. V ceně je také administrativa spojená s vyřízením <a href="/slovnik-pojmu/umrtni-list/">úmrtního listu</a> a možnost obracet se na naši zákaznickou linku 24 hodin 7 dní v týdnu.`
  },
  {
    otazka: "Budu platit něco navíc?",
    odpoved: `Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí zesnulého došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom <a href="/slovnik-pojmu/vyzvednuti-zesnuleho/">nemocnice</a> nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás. Podobný poplatek může vystavit krematorium pokud měl pozůstalý více než 100kg a musela by se použít nadměrná rakev.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo dané město. To se pak počítá podle <a href="/slovnik-pojmu/cena-za-dopravu/">najetých kilometrů</a>.<br/><br/>Pokud jsme pro vašeho blízkého jeli nebo pojedeme k vám domů nebo do zdravotní instituce, která nedisponuje vlastním chladícím zařízením, bude k celkové částce připočítán poplatek 1.990 Kč (v Praze, Ostravě a Libereckém kraji 3.490 Kč), který pokrývá pohotovostní služby zaměstnanců zajišťujících akutní vyzvednutí.`
  },
  {
    otazka: "Kdy a jak se platí?",
    odpoved: "Platba zálohy probíhá vždy předem. Je možné ji provést buď bankovním převodem nebo online přes platební bránu. Fakturu s údaji o platbě obdržíte po vytvoření závazné objednávky. Po pohřbu zákazník obdrží druhou fakturu za zbytek částky která není obsažená v záloze. Například v případě, že využijete i některých dalších služeb které nabízíme: jiná urna, květiny a podobně. Moc rádi vám v případě zájmu zařídíme i platbu na splátky skrz naše partnery. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Je možné platit na splátky?",
    odpoved: "Ano, u nás můžete platit na splátky. Prostřednictvím našich partnerů jsme vám schopni splácení faktury zařídit do 24 hodin. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Jaké dokumenty budu potřebovat?",
    odpoved: `Pokud je zesnulý doma, budete k předání pohřební službě potřebovat <a href="/slovnik-pojmu/list-o-prohlidce-tela/">ohledací list</a>, který vystaví Zdravotnická záchranná služba nebo <a href="/slovnik-pojmu/koroner/">koroner</a>. Pokud je v nemocnici nebo pečovatelském domě, postarají se o vystavení dokumentu i předání pohřební službě příslušní zaměstnanci. Dále od vás budeme potřebovat <span>kopii vašeho občanského průkazu</span>, občanský průkaz zesnulé/ho a <span>plnou moc</span>, kterou s vámi podepíšeme online. Ta se musí vystavit proto, abychom mohli manipulovat s tělem zesnulého.`
  },
  {
    otazka: "Poskytujete služby po celé ČR?",
    odpoved: `V současné chvíli poskytujeme pohřební služby téměř po celé ČR. Konkrétní město si můžete zkontrolovat v naší sekci <a href="/pobocky/">pobočky</a>. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.`
  },
  {
    otazka: "Jak si můžu být jistý/á, že nedojde k záměně popela?",
    odpoved: `Krematorium je ze zákona o pohřebnictví povinno do <a href="/rakve/">rakve</a> vkládat kovový plíšek s označením. Ten není během kremace poškozen a následně je i s popelem vložen do stejně označené <a href="/poradna/kremacni-popel/">urny</a>. Krematorium vede o celém procesu podrobnou evidenci, takže záměna je vyloučena.`
  },
];



const Praha = ({ data }) => {

  return (
    <StaticQuery
      query={graphql`
        query {
          jumboObrazek: file(relativePath: { eq: "praha.png" }) {
            childImageSharp {
              fixed(width: 460) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          jumboObrazekMobile: file(relativePath: { eq: "praha_m.png" }) {
            childImageSharp {
              fixed(width: 460) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          motol: file(relativePath: { eq: "krematoriumMotol.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          olsany: file(relativePath: { eq: "olsany.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          baxova: file(relativePath: { eq: "baxova.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          strasnice: file(relativePath: { eq: "strasnice.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          olsany2: file(relativePath: { eq: "olsany2.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          praha2: file(relativePath: { eq: "praha-2.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          praha3: file(relativePath: { eq: "praha-3.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          praha5: file(relativePath: { eq: "praha-5.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          praha4: file(relativePath: { eq: "praha-4.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          praha6: file(relativePath: { eq: "praha-6.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          praha8: file(relativePath: { eq: "praha-8.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          praha9: file(relativePath: { eq: "praha-9.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          praha10: file(relativePath: { eq: "praha-10.jpeg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={data => ( <>
        <RegionyTemplateV2
          name="Pohřební služba Praha"
          slug="pohrebni-sluzba-praha"
          faqData={faqData}
          noOtherRegions
          // Pohřební služba Praha | Empatická, osobní, dostupné ceny, 24/7 
          // seoTitle="Pohřební služba Praha - Empatická & Dostupné ceny | Goodbye"
          // Goodbye: Pohřební služba Praha - Nejlépe hodnocená za 2025
          // Pohřební služba Praha | Goodbye - Nejlépe hodnocená za 2025

          seoTitle="Pohřební služba Praha - Nejlépe hodnocená za 2025 - Goodbye"
          // seoDesc="Nejlépe hodnocená pohřební služba v Praze (Bulovka, Vinohrady, Motol): Pohřeb s obřadem, bez obřadu. Sjednání z domova 24/7. Nízké ceny od 13 900 Kč"
          seoDesc="Empatická & důstojná. Pohřební služba v Praze (Bulovka, Vinohrady, Motol): Pohřeb s obřadem, bez obřadu. Sjednání z domova 24/7. Ceny od 13 900 Kč."
          ratingCount={89}



          h1="Pohřební služba Praha"
          perex={<>Naplánujeme s vámi jedinečný pohřeb v Praze z bezpečí domova a za předem stanovenou cenu. Přesně takové rozloučení, jaké by si váš blízký přál. Možné je rozloučení v některé z pražských síní nebo jednoduchá kremace bez obřadu.<br/><br/>Postaráme se o vše potřebné, abyste se vy a vaše rodina mohli věnovat v těchto těžkých časech především jeden druhému.</>}
          jumboObrazek={data.jumboObrazek.childImageSharp.fixed}
          jumboObrazekMobile={data.jumboObrazekMobile.childImageSharp.fixed}
          reviewDesc="Nejlépe hodnocená pohřební služba v Praze za rok 2025"
          customCtaText="Získejte nabídku na Prahu"

          sklonovaneMesto="pražských"

          introH2="V čem jsme jiní než ostatní pohřební služby v Praze?"
          introText={<>Řešení úmrtí blízkého je možná ta nejtěžší situace v životě člověka: plná stresu, zmatku a smutku. Sami jsme se v minulosti setkali s neempatickým přístupem několika pražských pohřebních služeb. Vypravení pohřbu bylo chladné, neosobní a vždy za neúměrně vysokou cenu. To jsme se rozhodli změnit.<br/><br/>Založili jsme Goodbye s důrazem na osobní přístup a transparentnost – jako pohřební službu, kterou bychom s klidným srdcem nabídli vlastní rodině a přátelům. U nás nemusíte nikam chodit. Celý pohřeb si nezávazně sestavíte přes internet a rovnou se dozvíte konečnou cenu.<br/><br/>Náš empatický tým odborníků vám následně pomůže uspořádat jedinečné poslední rozloučení v <Link to="/pohreb-s-obradem/">tradičním stylu</Link> v Olšanech, Motole nebo Praze-Strašnicích. Také je možnost <Link to="/kremace-bez-obradu/">kremace bez obřadu</Link> či <Link to="/netradicni-pohreb/">alternativní</Link> podoby pohřbu.</>}

          procesDesc="V klidu vás provedeme celým procesem a zařídíme, co je potřeba. Věříme, že si každý zaslouží důstojné poslední rozloučení. Uděláme vše pro to, abychom vám a vaší rodině pomohli uspořádat v Praze to pravé."
          procesText1={<>Zajistíme převoz vašeho blízkého do našeho pražkého zázemí. Může se jednat i o <Link to="/slovnik-pojmu/vyzvednuti-zesnuleho/">akutní vyzvednutí z domu</Link>, nemocnice nebo jiné pohřební služby.</>}
          procesText2={<>Pomůžeme vám naplánovat osobní rozloučení podle vašich přání. Může se jednat o <Link to="/pohreb-s-obradem/">tradiční pohřeb v krematoriu</Link>, <Link to="/kremace-bez-obradu/">kremaci bez obřadu</Link> nebo i <Link to="/netradicni-pohreb/">netradiční variantu</Link>.</>}
          procesText3={<>Zajistíme pietní a důstojnou <Link to="/poradna/kremace-zajimavosti/">kremaci</Link> v našem partnerském krematoriu. Mezi tím za vás vyřešíme veškerou dokumentaci a <Link to="/slovnik-pojmu/umrtni-list/">úmrtní list</Link> na matrice.</>}
          procesText4={<>Urnu si u nás můžete vyzvednout nebo vám ji přivezeme až domů. Popel potom můžete <Link to="/slovnik-pojmu/rozptyl-vsyp-popela/">rozptýlit</Link>, <Link to="/slovnik-pojmu/hrob/">uložit do kolumbária</Link> či například <Link to="/poradna/kremacni-popel/">zasadit strom</Link>. Je to jen na vás.</>}


          customCtaText2="Zjistit přesnou cenu v Praze"
          cenikNapis="Ceník pohřební služby Praha"

          kremaceText="Postaráme se o vyzvednutí a následnou kremaci. Urnu vám předáme po Praze. Forma rozloučení je potom jen na vás."
          kremaceCena="16 490"
          kremaceSluzby={[
            "Sjednání za pár minut přes internet",
            "Vyřízení všech dokumentů v Praze",
            "Tvorba parte",
          ]}

          pohrebCena="25 490"
          pohrebText={"Řekněte svému blízkému sbohem při tradičním pohřbu ve smuteční síni v Olšanech, Motole nebo Praze-Strašnicích."}
          pohrebSluzby={[
            "Řečník, hudba, prezentace fotek",
            "Vyřízení veškerých dokumentů",
            "30-minutový obřad v pražské síni",
          ]}
          
          mestoH2="Máte nějaký speciální požadavek v Praze?"
          mestoText={<>
            Samozřejmě vám se vším rádi pomůžeme. Zavolejte nám a naši sjednavatelé se s vámi domluví na <Link to="/poradna/smutecni-kytice-pohreb/">výzdobě</Link>, <Link to="/poradna/smutecni-rec-rady-tipy/">řečníkovi</Link>, <Link to="/poradna/pruvodce-tvorbou-smutecniho-oznameni/">parte</Link> nebo čemkoli jiném, co byste si na <Link to="/poradna/jak-naplanovat-pohreb/">pohřbu přáli</Link>.<br/><br/>
            Samozřejmostí je vyzvednutí vašeho blízkého pohřební službou kdekoliv po Praze, ať už se jedná o fakultní nemocnici Bulovka, Motol, Vinohrady, Thomayerovu nemocnici nebo domovy pro seniory či <Link to="/seznam-hospicu/">hospice</Link>. V případě <Link to="/slovnik-pojmu/vyzvednuti-zesnuleho/">úmrtí doma</Link> jsme na místě do několika hodin od prvního telefonátu. Provádíme i akutní vyzvednutí mimo pracovní dobu nebo o víkendech a svátcích. Doba příjezdu se může mírně lišit v návaznosti na konkrétní vzdálenost. Ze zkušenosti jsme ale na místě nejpozději do 3 hodin od zavolání, ať se jedná o <Link to="/pohrebni-sluzba-praha/praha-2/">Prahu 2</Link>, <Link to="/pohrebni-sluzba-praha/praha-3/">Prahu 3</Link>, <Link to="/pohrebni-sluzba-praha/praha-4/">Prahu 4</Link>, <Link to="/pohrebni-sluzba-praha/praha-5/">Prahu 5</Link>, <Link to="/pohrebni-sluzba-praha/praha-6/">Prahu 6</Link>, <Link to="/pohrebni-sluzba-praha/praha-8/">Prahu 8</Link>, <Link to="/pohrebni-sluzba-praha/praha-9/">Prahu 9</Link> nebo <Link to="/pohrebni-sluzba-praha/praha-10/">Prahu 10</Link>.<br/><br/>
            Pokud využíváte služeb <Link to="/slovnik-pojmu/hospic/">domácího hospice</Link>, je možné se s námi spojit dopředu a domluvit se na postupu. V takovém případě vám v momentě odchodu vašeho blízkého budeme schopni situaci co možná nejvíce ulehčit.<br/><br/>
            Naše pohřební služba Praha nabízí různé typy pohřbů: pohřeb v několika pražských <Link to="/obradni-sine/">obřadních síních</Link>, kremaci bez obřadu a netradiční rozloučení v přírodě. Nabízíme také širokou nabídku <Link to="/rakve/">rakví</Link>, <Link to="/poradna/smutecni-kytice-pohreb/">květin</Link>, věnců a dalších <Link to="https://www.prozeny.cz/clanek/pohrebnicke-trendy-miri-do-ceska-obrad-naplanujete-z-pohodli-domova-81281">smutečních předmětů</Link>.
          </>}
          mapLocation={"Praha"}

          featuresH2="Profesionalita a důstojnost je pro nás na prvním místě"
          featuresText1={<>Ať váš blízký <Link to="/slovnik-pojmu/vyzvednuti-zesnuleho/">zemřel doma</Link>, v nemocnici na Bulovce, v hospici u Dobrého pastýře nebo kdekoli jinde, nemusíte nikam chodit. Vše vyřídíte z bezpečí vašeho domova, kde si můžete detaily posledního rozloučení v klidu rozmyslet a probrat je s rodinou.</>}
          featuresText2="Cena, na které se s naší pohřební službou domluvíte, je vždy konečná. Obsahuje kompletně vše, co budete potřebovat, včetně paušálních kilometrů po celé Praze."
          featuresText3={<>Vytiskneme pro vás parte a zavezeme vám jej kamkoliv po Praze a blízkém okolí. Vyřídíme komunikaci s matrikou pro vystavení <Link to="/slovnik-pojmu/umrtni-list/">úmrtního listu</Link>. Osobně vám doručíme urnu domů nebo na místo podle vašeho přání.</>}

          seVsimPomuzemeText="Chápeme, že smrt blízkého je situace, kterou neplánujete. Zavolejte nám a my jsme připraveni vaši situaci okamžitě řešit. Se vším vám pomůžeme a vytvoříme pro vás a vaše blízké to nejosobnější poslední rozloučení v Praze a okolí."
          
          obradniSineH2="Pražské obřadní síně"
          obradniSine={[
            {
              h3: "Ústřední obřadní síň Praha-Olšany",
              img: data.olsany.childImageSharp.fluid,
              link: "/obradni-sine/obradni-sin-praha-olsany/",
              text: <>Obřad v Ústřední obřadní síni trvá zhruba 25 minut, během kterých zazní několik skladeb vašeho výběru a smuteční řeč, kterou pronese náš <Link to="/poradna/smutecni-rec-rady-tipy/">řečník</Link>. Tuto promluvu můžete buď nechat na řečníkovi nebo ji můžete napsat i pronést sami. Síň je vybavena dvěma obrazovkami, na kterých je možnost promítání fotek či videa. Díky této funkci je možné mít rozloučení velmi osobní a zároveň důstojné.</>,
              address: "Vinohradská 71/155, 130 00 Praha 3-Žižkov",
            },
            {
              h3: "Nová obřadní síň Praha-Olšany",
              img: data.olsany2.childImageSharp.fluid,
              link: "/obradni-sine/nova-obradni-sin-praha-olsany/",
              text: <>V rámci <Link to="/poradna/olsanske-hrbitovy/">Olšanských hřbitovů</Link> najdeme i Novou obřadní síň v Praze. Je kvůli své velikosti vhodná spíše pro osobnější rozloučení určená pro nižší počet smutečních hostů. Celková kapacita síně je 56 osob, z toho je 23 míst k sezení. Síň je vybavena katafalkem, vyhrazeným místem pro řečníka či faráře a LCD obrazovkou, na které je možné během obřadu promítat fotografie či video. Prostor je vhodný pro civilní i církevní formy obřadu.</>,
              address: "Vinohradská 949/157, 130 00 Praha 3-Žižkov",
            },
            {
              h3: "Obřadní síň Motol",
              img: data.motol.childImageSharp.fluid,
              link: "/obradni-sine/obradni-sin-motol/",
              text: <>Tato obřadní síň se nachází v areálu Motolského hřbitova společně s <Link to="/poradna/krematorium-motol-praha/">krematoriem Motol</Link>. Její celková kapacita je zhruba 120 osob, z toho 40 míst je k sezení. Obřady trvají 25 - 30 minut. Po pravé straně od katafalku se nachází místo pro řečníka, nad nímž je umístěna obrazovka připravená pro promítání fotek. Hudba je reprodukovaná a vybrat si ji můžete buď z připraveného katalogu nebo přinést na flash disku nachystané vlastní písně.</>,
              address: "Plzeňská 30/233, Praha 5-Motol",
            },
            {
              h3: "Malá obřadní síň Krematorium Strašnice (Baxova)",
              img: data.baxova.childImageSharp.fluid,
              link: "/obradni-sine/mala-obradni-sin-praha-strasnice/",
              text: <>Malá, také známá jako Baxova, obřadní síň <Link to="/poradna/strasnice-krematorium/">strašnického krematoria</Link> je po pravé straně od Velké síně. Díky menšímu, přesto však důstojnému prostoru, je síň ideálním místem pro více intimní a komorní smuteční obřady. Kapacita Baxovy obřadní síně je cca 80 smutečních hostů, z toho cca 24 míst je pro sezení. Síň je vybavená příjemnou umělou květinovou výzdobou.</>,
              address: "Vinohradská 2509/214, Praha 10 Vinohrady",
            },
            {
              h3: "Velká obřadní síň Krematorium Strašnice Praha",
              img: data.strasnice.childImageSharp.fluid,
              link: "/obradni-sine/velka-obradni-sin-praha-strasnice/",
              text: <>Velká obřadní síň <Link to="/poradna/strasnice-krematorium/">Krematoria Strašnice</Link> je dominantou celé budovy krematoria a zároveň velmi žádaným místem pro pořádání posledních rozloučení. Síň je velmi vzdušná a prostorná, její celková plocha je 450 metrů čtverečních a strop je vysoký 16 metrů. Kapacita činí 200 smutečních hostů, z toho 63 míst je k sezení. Vpředu uprostřed je umístěn katafalk, díky jehož nezvyklé výšce - dva a půl metru - je bez problémů viditelný ze všech koutů a stran síně. Nad katafalkem je tvoří ozdobu síně umělecké dílo sochaře J. Palouše.</>,
              address: "Vinohradská 2509/214, Praha 10 Vinohrady",
            }
          ]}

          dalsiOdkazy={[
            {
              h4: "Pohřební služba Praha 2: Nové Město",
              p: "Pokud váš blízký zemřel ve Všeobecné fakultní nemocnici, v Centru sociálních služeb nebo u vás doma kdekoli jinde po Praze 2.",
              link: "/pohrebni-sluzba-praha/praha-2/",
              img: data.praha2.childImageSharp.fluid,
            },
            {
              h4: "Pohřební služba Praha 3: Vinohrady",
              p: "Pokud váš blízký zemřel v Nemocnici Královské Vinohrady, Nemocnici na Žižkově, v Ošetřovatelském Domově nebo u vás doma kdekoli jinde po Praze 3.",
              link: "/pohrebni-sluzba-praha/praha-3/",
              img: data.praha3.childImageSharp.fluid,
            },
            {
              h4: "Pohřební služba Praha 4: Krč, Modřany",
              p: "Pokud váš blízký zemřel v Thomayerově nemocnici, v Domově pro seniory Krč, v Modřanech nebo u vás doma kdekoli jinde po Praze 4.",
              link: "/pohrebni-sluzba-praha/praha-4/",
              img: data.praha4.childImageSharp.fluid,
            },
            {
              h4: "Pohřební služba Praha 5: Motol, Smíchov",
              p: "Pokud váš blízký zemřel v Nemocnici Motol, v SeneCura SeniorCentru Klamovka nebo u vás doma kdekoli jinde po Praze 5.",
              link: "/pohrebni-sluzba-praha/praha-5/",
              img: data.praha5.childImageSharp.fluid,
            },
            {
              h4: "Pohřební služba Praha 6: Břevnov, Střešovice",
              p: "Pokud váš blízký zemřel v Ústřední vojenské nemocnici, v Domově pro seniory Elišky Purkyňové nebo u vás doma kdekoli jinde po Praze 6.",
              link: "/pohrebni-sluzba-praha/praha-6/",
              img: data.praha6.childImageSharp.fluid,
            },
            {
              h4: "Pohřební služba Praha 8: Bulovka",
              p: "Hledáte pohřební službu v blízkosti Fakultní nemocnice Bulovka, v Domova pro seniory Kobylisy, v Libni, Bohnicích nebo u vás doma kdekoli jinde po Praze 8.",
              link: "/pohrebni-sluzba-praha/praha-8/",
              img: data.praha8.childImageSharp.fluid,
            },
            {
              h4: "Pohřební služba Praha 9: Čakovice, Vysočany",
              p: "Pokud váš blízký zemřel v Nemocnici ve Vysočanech, v Domově pro seniory Slunné stáří, v Malešicích nebo u vás doma kdekoli jinde po Praze 9.",
              link: "/pohrebni-sluzba-praha/praha-9/",
              img: data.praha9.childImageSharp.fluid,
            },
            {
              h4: "Pohřební služba Praha 10: Strašnice",
              p: "Řešíte úmrtí blízkého ve Fakultní nemocnici Královské Vinohrady, v Domově pro seniory Zahradní Město, ve Strašnicích nebo u vás doma kdekoli jinde po Praze 10.",
              link: "/pohrebni-sluzba-praha/praha-10/",
              img: data.praha10.childImageSharp.fluid,
            },
          ]}
        />
      </>)}
    />
  );
}

export default Praha;


